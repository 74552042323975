import { Inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { EnvironmentConfiguration, ENV_CONFIG } from '../model/environment-configuration';
import { DEFAULT_INTERSTITIAL_MESSAGE, LONG_POST_KEY } from '../utils/constants';

@Injectable({
  providedIn: 'root'
})
export class InterstitialService {

  private interstitialSubject: BehaviorSubject<string> = new BehaviorSubject(null);
  public interstitial: Observable<string> = this.interstitialSubject.asObservable();

  private cancelSubject: Subject<void> = new Subject<void>();
  public onCancel: Observable<void> = this.cancelSubject.asObservable();

  private timer: any = null;

  constructor(
    private translate: TranslateService,
    @Inject(ENV_CONFIG) private envConfig: EnvironmentConfiguration
  ) { }

  public close() {
    if (!_.isNil(this.timer)) {
      clearTimeout(this.timer);
    }

    this.setInterstitialMessage(null);
  }

  public open(messageKey?: string) {
    this.setInterstitialMessage(messageKey || DEFAULT_INTERSTITIAL_MESSAGE);
    this.setupLongRunningRequestMessage(messageKey);
  }

  public cancel(): void {
    this.close();
    this.cancelSubject.next();
  }

  private setupLongRunningRequestMessage(messageKey: string): void {
    const longKey = messageKey + LONG_POST_KEY;
    const requestTimeoutInMinutes = Math.floor(this.envConfig.requestTimeout / 60000);
    const longRunningMessage = this.translate.instant(longKey, { requestTimeout: requestTimeoutInMinutes });

    // if the key doesn't exist, the translate servie just returns the
    // key, so check to see if the two are equal, if so, return (no translation exists)
    if (longKey === longRunningMessage) {
      return;
    }

    const threshold = this.envConfig.longRunningRequestThreshold;

    // otherwise, show the long running message after a time
    this.timer = setTimeout(() => this.setInterstitialMessage(longRunningMessage), threshold);
  }

  private setInterstitialMessage(messageOrKey: string): void {
    this.timer = null;
    this.interstitialSubject.next(messageOrKey);
  }

}
