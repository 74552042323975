import { Inject, Injectable } from '@angular/core';
import { ENV_CONFIG, EnvironmentConfiguration } from '../model';

@Injectable({
  providedIn: 'root'
})
export class LoggingService {

  constructor(
    @Inject(ENV_CONFIG) private envConfig: EnvironmentConfiguration
  ) { }

  public logInfo(message: string): void {
    if (!this.envConfig.production) {
      // TODO: Integrate log4net or the desired logging service
      console.log(message);
    }
  }

  public logError(error: string | any): void {
    if (!this.envConfig.production) {
      // TODO: Integrate log4net or the desired logging service
      console.error(error);
    }
  }
}
