import { InjectionToken } from '@angular/core';

export interface EnvironmentConfiguration {
  production: boolean;
  serviceUrl: string;
  customerServiceUrl?: string;
  requestTimeout: number;
  longRunningRequestThreshold?: number;
  showTestLogins?: string;
  loginUrl?: string;
  requireDateRange: boolean;
}

export const ENV_CONFIG = new InjectionToken<EnvironmentConfiguration>('ENV_CONFIG');
