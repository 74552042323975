import { Component, Input, OnInit } from '@angular/core';

const NAVBAR = 'navbar';

@Component({
  selector: 'ods-dropdown-button',
  templateUrl: './dropdown-button.component.html',
})
export class DropdownButtonComponent implements OnInit {
  @Input() public type = '';

  @Input() public showDropdown = true;  // true by default
  @Input() public dropdownId = '';
  @Input() public btnName = '';

  @Input() public divClasses = ['dropdown'];
  @Input() public buttonClasses = ['btn'];

  constructor() { }

  public ngOnInit(): void {
    if (this.type === NAVBAR) {
      this.divClasses.push('nav-dropdown');
      this.buttonClasses.push('btn-light', 'dropdown-toggle');
    }
  }

}
