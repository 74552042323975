<div class="container-fluid">
  <div class="row app-header thin">
    <div class="header-left">
      <div class="header-logo sr-only" translate>app.title</div>
    </div>
    <div class="header-right">
      <div class="btn-group">
        <a href="{{customerServiceUrl}}" translate>app.name</a>
      </div>

      <div *ngIf="showDropdown" class="btn-group ml-4">
        <button id="userDropdown" class="btn btn-link dropdown-toggle" data-toggle="dropdown">
          {{'app.header.welcome.user' | translate: user}}
        </button>
        <div class="dropdown-menu dropdown-menu-right">
          <button (click)="logout()" class="dropdown-item" data-se-id="logout-button" type="button" translate>app.header.signout</button>
        </div>
      </div>
    </div>
  </div>
</div>