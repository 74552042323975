import { Component, HostListener, Input } from '@angular/core';
import { MenuService } from './../../services/menu/menu.service';

@Component({
  selector: 'ods-view-content',
  templateUrl: './view-content.component.html',
})
export class ViewContentComponent {
  @Input() public componentTitle = '';
  @Input() public closeButtonRouterLink = '';
  @Input() public closeButtonExternalLink = '';
  @Input() public closeButtonLabel = '';
  @Input() public small = false;

  constructor(private menuService: MenuService) {}

  @HostListener('click')
  public onClick(): void {
    if (this.menuService.getIsExpandedMenu() && this.menuService.getIsClickedMenu()) {
      this.menuService.setIsClickedMenu(false);
      this.menuService.setIsExpandedMenu(false);
    }
  }
}
