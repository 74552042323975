import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {DocumentInterruptSource, Idle, StorageInterruptSource} from '@ng-idle/core';
import {ModalDirective} from 'ngx-bootstrap/modal';

@Component({
  selector: 'ods-session-timeout',
  templateUrl: './session-timeout.component.html',
})
export class SessionTimeoutComponent implements OnInit {
  public idleStateCounter = 'Not started.';
  public timedOut = false;
  @Input() public redirectionUrl = '';
  @Input() public warningHeaderMessage = '';

  @Input() public warningBodyMessage = '';

  @Input() public timeoutWarningMessageDuration = 60;

  @Input() public idleTimeoutDuration = 840;

  @ViewChild('childModal', {static: false})
  private _childModal!: ModalDirective;

  constructor(private router: Router, private idle: Idle) {

  }


  // @ts-ignore
  public createCustomInterruptSources = (options) => [
    new DocumentInterruptSource('keydown mousedown touchstart touchmove' +
      'scroll', options),
    new StorageInterruptSource(options),
  ]

  public ngOnInit(): void {

    this.idle.setIdle(Number(this.idleTimeoutDuration));
    this.idle.setTimeout(Number(this.timeoutWarningMessageDuration));
    this.idle.setInterrupts(this.createCustomInterruptSources(null));

    this.idle.onIdleEnd.subscribe(() => {
      this.reset();
    });

    this.idle.onTimeout.subscribe(() => {
      this._childModal.hide();
      this.timedOut = true;
      this.router.navigateByUrl(this.redirectionUrl);
    });

    this.idle.onIdleStart.subscribe(() => {
      this._childModal.show();
    });

    this.idle.onTimeoutWarning.subscribe((countdown: string) => {
      this.idleStateCounter = '00:' + countdown;
    });

    this.idle.watch();
  }

  public reset = () => {
    this.idle.watch();
    this.timedOut = false;
  }

  public stay = () => {
    this._childModal.hide();
    this.reset();
    location.reload();
  }

  public logout = () => {
    this._childModal.hide();
    this.reset();
    this.router.navigateByUrl(this.redirectionUrl);
  }
}

