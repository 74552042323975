<div class="spinner" style="position: absolute">
  <div class="circle">
    <div class="inner"></div>
    <div class="inner"></div>
    <div class="inner"></div>
    <div class="inner"></div>
    <div class="inner"></div>
    <div class="inner"></div>
  </div>
  <p class="lead">
    {{message | translate}}
    <span>
      <br />
      <small><a href="#" (click)="clickCancel($event)" translate>app.cancel</a></small>
    </span>
  </p>
</div>
<div id="spinner-backdrop" class="spinner-backdrop"></div>