import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { LoggingService } from '../service';

@Pipe({ name: 'safe-url' })
export class SafeUrlPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer, private log: LoggingService) { }
  transform(url: string): SafeResourceUrl {
    this.log.logInfo('safe pipe URL: ' + url);
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
}
