import { Directive, ElementRef, Input, OnInit } from '@angular/core';

@Directive({
  selector: '[coreFocus]'
})
export class FocusDirective implements OnInit {

  private _focus = false;

  @Input('coreFocus')
  set coreFocus(value: boolean) {
    this._focus = value;

    if (this._focus) {
      this.hostElement.nativeElement.focus();
    }
  }

  constructor(
    private hostElement: ElementRef
  ) { }

  ngOnInit(): void {
  }

}
