import * as _ from 'lodash';
// https://github.com/ng-packagr/ng-packagr/issues/217
import * as momentImported from 'moment';
const moment = momentImported;
import {
  DATE_FORMAT,
  DATE_INPUT_FORMAT,
  INVALID_DATE_MESSAGE,
} from './constants';

export class InputToRequestTransformerGeneric {
  public mapProperties(propNames: string[], from: any, criteria: any): void {
    // filters out null|undefined property names in 'from' then copies the remaining properties names 'from' -> 'to'
    _.filter(
      propNames,
      (name) => !_.isNil(from[name]) && from[name] !== ''
    ).forEach((name) => (criteria[name] = from[name]));
  }

  public mapProperty(propName: string, criteria: any, value: any): void {
    if (!_.isNil(value)) {
      criteria[propName] = value;
    }
  }

  public formatDateString(dateString: string): string {
    if (!_.isNil(dateString)) {
      const formattedDateString = moment(dateString, DATE_INPUT_FORMAT).format(
        DATE_FORMAT
      );

      // TODO for now, ignore invalid dates - probably need UI input validation in the future
      if (formattedDateString !== INVALID_DATE_MESSAGE) {
        return formattedDateString;
      }
    }

    return null;
  }

  public formatDateInputString(dateString: string): string {
    if (!_.isNil(dateString)) {
      const formattedDateString = moment(dateString, DATE_INPUT_FORMAT).format(
        DATE_INPUT_FORMAT
      );

      // TODO for now, ignore invalid dates - probably need UI input validation in the future
      if (formattedDateString !== INVALID_DATE_MESSAGE) {
        return formattedDateString;
      }
    }

    return null;
  }
}
