import { Component, Inject, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/internal/operators';
import { ENV_CONFIG, EnvironmentConfiguration } from '../../model';
import { AuthService, TitleService } from '../../service';

const ACCESS_DENIED_TITLE_KEY = 'app.accessDenied.title';
const WARNING_CLASS_KEY = 'warning';

@Component({
  selector: 'core-access-denied',
  templateUrl: './access-denied.component.html'
})
export class AccessDeniedComponent implements OnInit {

  public pageTitle = '';
  public allowLoginOverride = false;
  public hrefTarget = '/';

  constructor(
    private authService: AuthService,
    private titleService: TitleService,
    private translate: TranslateService,
    @Inject(ENV_CONFIG) private envConfig: EnvironmentConfiguration
  ) { }

  ngOnInit() {
    this.authService.logout();

    this.translate.get(ACCESS_DENIED_TITLE_KEY).pipe(take(1)).subscribe(title => {
      this.pageTitle = title;
      this.titleService.setTitle(title, WARNING_CLASS_KEY);
    });

    this.allowLoginOverride = !this.envConfig.production;
    this.hrefTarget = this.envConfig.loginUrl;
  }

}
