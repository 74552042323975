export const AUTHORIZATION_PARAMETER = 'Authorization';

export const JWT_TOKEN_STORAGE_KEY = 'jwt_key';

export const DEFAULT_INTERSTITIAL_MESSAGE = ' ';
export const LONG_POST_KEY = '.long';

export const DATE_FORMAT = 'YYYY-MM-DD';
export const DATE_INPUT_FORMAT = 'MM/DD/YYYY';
export const INVALID_DATE_MESSAGE = 'Invalid date';

export const INTERSTITIAL_CONTENT_PREFIX = 'interstitial.content';

