import { Component, OnInit, ViewChild } from '@angular/core';
import { ToastContainerDirective, ToastrService } from 'ngx-toastr';

@Component({
  selector: 'ods-alert-toast-container',
  templateUrl: './alert-toast-container.component.html',
})
export class AlertToastContainerComponent implements OnInit {
  @ViewChild(ToastContainerDirective) public toastContainer!: ToastContainerDirective;

  constructor(
    private toastrService: ToastrService,
  ) { }

  public ngOnInit(): void {
    this.toastrService.overlayContainer = this.toastContainer;
  }
}
