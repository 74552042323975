import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { take, tap } from 'rxjs/internal/operators';

const APP_TITLE_KEY = 'app.title';
const APP_TITLE_TYPE_KEY = 'app.title.type';

@Injectable({
  providedIn: 'root'
})
export class TitleService {

  private applicationTitle: string = null;

  private titleSubject = new BehaviorSubject<string>(APP_TITLE_KEY);
  private titleTypeSubject = new BehaviorSubject<string>(APP_TITLE_TYPE_KEY);

  public title = this.titleSubject.asObservable();
  public type = this.titleTypeSubject.asObservable();

  constructor(
    private titleService: Title,
    private translate: TranslateService
  ) { }

  public setTitle(titleKey?: string, typeClassKey?: string): void {
    this.getApplicationTitle()
      .pipe(take(1))
      .subscribe(() => {
        this.translate.get(titleKey || ' ').subscribe((newTitle) => {
          this.titleService.setTitle(`${this.applicationTitle} | ${newTitle}`);
          this.titleSubject.next(newTitle);
          this.titleTypeSubject.next(typeClassKey);
        });
      });
  }

  private getApplicationTitle(): Observable<string> {
    if (!_.isNil(this.applicationTitle)) {
      return of(this.applicationTitle);
    }

    return this.translate.get(APP_TITLE_KEY).pipe(tap((title) => this.applicationTitle = title));
  }
}
