import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

const HOME_PATH = '';
const SIGNED_OUT_PATH = '/signed-out';
const ACCESS_DENIED_PATH = '/access-denied';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {

  constructor(
    protected router: Router
  ) { }

  public reload(params?: any): Promise<boolean> {
    return this.router.navigate(['.'], params);
  }

  public goHome(): Promise<boolean> {
    return this.router.navigate([HOME_PATH]);
  }

  public goToAccessDenied(): Promise<boolean> {
    return this.router.navigate([ACCESS_DENIED_PATH]);
  }

  public goToSignedOut(): Promise<boolean> {
    return this.router.navigate([SIGNED_OUT_PATH]);
  }
}
