import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { BsDatepickerConfig, BsDaterangepickerDirective } from 'ngx-bootstrap/datepicker';

@Component({
  selector: 'ods-date-range-picker',
  templateUrl: './date-range-picker.component.html',
})
export class DateRangePickerComponent implements OnInit {
  public readonly defaultDateInputFormat: string = 'MM/DD/YYYY';

  public get selectedStartDate(): Date {
    let selectedStartDate!: Date;

    if (this.hasValidSelectedDateRange()) {
      selectedStartDate = this.selectedDates[this.selectedStartDateIndex];
    }

    return selectedStartDate;
  }

  public get selectedEndDate(): Date {
    let selectedEndDate!: Date;

    if (this.hasValidSelectedDateRange()) {
      selectedEndDate = this.selectedDates[this.selectedEndDateIndex];
    }

    return selectedEndDate;
  }

  @Input() public get selectedDates(): Date[] {
    return this._selectedDates;
  }

  public set selectedDates(value: Date[]) {
    this._selectedDates = value;

    this.selectedDatesChangeEventEmitter.emit(value);
    this.selectedStartDateChangeEventEmitter.emit(this.selectedStartDate);
    this.selectedEndDateChangeEventEmitter.emit(this.selectedEndDate);
  }

  @Input() public get dateInputFormat(): string {
    return this._dateInputFormat;
  }

  public set dateInputFormat(value: string) {
    this._dateInputFormat = value;

    this.updateDateInputFormat();
  }

  @Input() public disabled = false;
  @Input() public placeholderText = `${this.defaultDateInputFormat} - ${this.defaultDateInputFormat}`;
  @Input() public minimumDate!: Date;
  @Input() public maximumDate!: Date;
  @Input() public isValid = true;

  @ViewChild(BsDaterangepickerDirective) public dateRangePicker!: BsDaterangepickerDirective;

  public configuration: Partial<BsDatepickerConfig> = {
    showWeekNumbers: false,

    // Source CSS classes are defined in
    // src/styles/_datepickers.scss
    containerClass: 'theme-ods',
    customTodayClass: 'today',

    // Any moment.js format
    // https://valor-software.com/ngx-bootstrap/#/datepicker#format
    dateInputFormat: this.dateInputFormat,
    rangeInputFormat: this.dateInputFormat,
  };

  // This Input/Output pairing follows the convention for creating two-way binding
  // https://blog.angulartraining.com/tutorial-create-your-own-two-way-data-binding-in-angular-46487650ea82
  @Output('selectedStartDateChange') public selectedStartDateChangeEventEmitter: EventEmitter<Date> = new EventEmitter();
  @Output('selectedEndDateChange') public selectedEndDateChangeEventEmitter: EventEmitter<Date> = new EventEmitter();
  @Output('selectedDatesChange') public selectedDatesChangeEventEmitter: EventEmitter<Date[]> = new EventEmitter();
  private readonly requiredSelectedDateRangeLength = 2;
  private readonly selectedStartDateIndex = 0;
  private readonly selectedEndDateIndex = 1;

  private _selectedDates: Date[] = [];
  private _dateInputFormat: string = this.defaultDateInputFormat;

  public ngOnInit(): void {
    this.updateDateInputFormat();
  }

  public onDateButtonClicked(): void {
    this.dateRangePicker.show();
  }

  private hasValidSelectedDateRange(): boolean {
    return this.selectedDates && this.selectedDates.length === this.requiredSelectedDateRangeLength;
  }

  private updateDateInputFormat(): void {
    if (this.configuration) {
      this.configuration.dateInputFormat = this.dateInputFormat;
      this.configuration.rangeInputFormat = this.dateInputFormat;
    }
  }
}
