<p id="{{id}}">
  <!--Address Line 1, City, and Country are non-null so no need for ngIf-->
  <span>
    {{address?.addressLine1 | titlecase}}
    <br />
  </span>
  <span *ngIf="address?.addressLine2">
    {{address?.addressLine2 | titlecase}}
    <br />
  </span>
  <span *ngIf="address?.addressLine3">
    {{address?.addressLine3 | titlecase}}
    <br />
  </span>
  <!-- State and Postal Code are nullable, so either show City by itself OR show all three -->
  <span *ngIf="address?.city && !(address?.state && address?.postalCode)">
    {{address?.city | titlecase}}
    <br />
  </span>
  <span *ngIf="address?.city && address?.state && address?.postalCode">
    {{address?.city | titlecase}}, {{address?.state}} {{address?.postalCode}}
    <br />
  </span>
  <!-- TODO translate from country code? -->
  <span>
    {{address?.country}}
  </span>
</p>