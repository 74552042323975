import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ENV_CONFIG, EnvironmentConfiguration } from '../model';

@Injectable({
  providedIn: 'root'
})
export class TestLoginService {

  constructor(
    private http: HttpClient,
    @Inject(ENV_CONFIG) private envConfig: EnvironmentConfiguration
  ) { }

  propertyTestUser(): Observable<TestLoginResponse> {
    return this.http.get<TestLoginResponse>(this.envConfig.serviceUrl + '/rest/testlogin/property');
  }

  brandTestUser(): Observable<TestLoginResponse> {
    return this.http.get<TestLoginResponse>(this.envConfig.serviceUrl + '/rest/testlogin/brand');
  }

  multiBrandTestUser(): Observable<TestLoginResponse> {
    return this.http.get<TestLoginResponse>(this.envConfig.serviceUrl + '/rest/testlogin/multibrand');
  }

  chainTestUser(): Observable<TestLoginResponse> {
    return this.http.get<TestLoginResponse>(this.envConfig.serviceUrl + '/rest/testlogin/chain');
  }

  agencyTestUser(): Observable<TestLoginResponse> {
    return this.http.get<TestLoginResponse>(this.envConfig.serviceUrl + '/rest/testlogin/agency');
  }

  paylocTestUser(): Observable<TestLoginResponse> {
    return this.http.get<TestLoginResponse>(this.envConfig.serviceUrl + '/rest/testlogin/payloc');
  }

  customerTestUser(): Observable<TestLoginResponse> {
    return this.http.get<TestLoginResponse>(this.envConfig.serviceUrl + '/rest/testlogin/customer');
  }

  supportTestUser(): Observable<TestLoginResponse> {
    return this.http.get<TestLoginResponse>(this.envConfig.serviceUrl + '/rest/testlogin/support');
  }
}

export class TestLoginResponse {
  jwtToken: string;
}
