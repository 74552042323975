export class BasicInput {
  public page: number;
  private INIT_PAGINATION_PAGE = 1;
  constructor() {
      this.page = this.INIT_PAGINATION_PAGE;
  }
  public onPreviousClicked(): void {
      if (this.page > this.INIT_PAGINATION_PAGE) {
        this.page--;
      }
  }

  public onNextClicked(): void {
      this.page++;
  }

  public resetPagination(): void {
      this.page = this.INIT_PAGINATION_PAGE;
  }

  public isfirstPage(): boolean {
      return this.page === this.INIT_PAGINATION_PAGE;
  }
}
