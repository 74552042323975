import {
  AfterContentInit,
  Component,
  ContentChildren,
  EventEmitter,
  Input, OnDestroy,
  OnInit,
  Output,
  QueryList,
} from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MenuProp } from '../../models/menu-prop.model';
import { MenuService } from '../../services';
import { LeftNavbarListItemComponent } from '../left-navbar-list-item/left-navbar-list-item.component';

@Component({
  selector: 'ods-left-navbar',
  templateUrl: './left-navbar.component.html',
})
export class LeftNavbarComponent implements OnInit, OnDestroy, AfterContentInit {
  @Input() public componentTitle = 'MENU';
  @Input() public expandButtonTitle = 'Expand';
  @Input() public collapseButtonTitle = 'Collapse';
  @Input() public autoCollapse = false;
  @Input() public modal = false;
  @Input() public showExpandedIndicators = true;

  @Output('expanded') public expandedEventEmitter: EventEmitter<MenuProp> = new EventEmitter<MenuProp>();
  @Output('collapsed') public collapsedEventEmitter: EventEmitter<MenuProp> = new EventEmitter<MenuProp>();

  // NOTE: Because list items are used inside the ng-content container, we must use ContentChildren instead of ViewChildren
  // https://netbasal.com/understanding-viewchildren-contentchildren-and-querylist-in-angular-896b0c689f6e
  @ContentChildren(LeftNavbarListItemComponent) public listItems!: QueryList<LeftNavbarListItemComponent>;

  public isExpanded: Observable<boolean> = this.menuService.isExpandedMenu$;
  public isClicked: Observable<boolean> = this.menuService.isClickeMenu$;

  public mouseOver = false;

  private unsubscribe: Subject<void> = new Subject();

  constructor(private menuService: MenuService) { }

  public ngOnInit(): void {
  }

  public ngAfterContentInit(): void {
    if (this.autoCollapse) {
      // Listen to the click event of each item so we can auto-collapse the navbar for navigation
      this.listItems.forEach((listItem: LeftNavbarListItemComponent) => {
        listItem.listItemClickEventEmitter
          .pipe(takeUntil(this.unsubscribe))
          .subscribe(() => {
            this.collapseNavbar();
          });
      });
    }
  }

  public ngOnDestroy(): void {
    // NOTE: To prevent memory leaks, we want to unsubscribe all listeners (in the simplest way possible); this
    // approach allows us to unsubscribe without having to keep track of all subscriptions
    // https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  public onExpandButtonClicked(event: Event): void {
    this.menuService.setIsClickedMenu(true);
    event.preventDefault();

    this.expandNavbar();

    this.expandedEventEmitter.emit({isExpanded: this.menuService.getIsExpandedMenu(), isClicked: this.menuService.getIsClickedMenu() });
  }

  public onCollapseButtonClicked(event: Event): void {
    this.menuService.setIsClickedMenu(false);
    event.preventDefault();

    this.collapseNavbar();

    this.expandedEventEmitter.emit({isExpanded: this.menuService.getIsExpandedMenu(), isClicked: this.menuService.getIsClickedMenu() });
  }

  public onBackdropClicked(): void {
    this.collapseNavbar();
  }

  public onMouseOvered(event: Event): void {
    if (!this.menuService.getIsExpandedMenu()) {
      event.preventDefault();

      this.mouseOver = true;
      this.expandNavbar();

      this.expandedEventEmitter.emit({isExpanded: this.menuService.getIsExpandedMenu(), isClicked: this.menuService.getIsClickedMenu() });
    }
  }

  public onMouseLeave(event: Event): void {
    if (this.menuService.getIsExpandedMenu() && !this.menuService.getIsClickedMenu()) {
      event.preventDefault();

      this.mouseOver = false;
      this.collapseNavbar();

     this.expandedEventEmitter.emit({isExpanded: this.menuService.getIsExpandedMenu(), isClicked: this.menuService.getIsClickedMenu() });
    }
  }

  private expandNavbar(): void {
    this.menuService.setIsExpandedMenu(true);
  }

  private collapseNavbar(): void {
    this.menuService.setIsExpandedMenu(false);
  }
}
