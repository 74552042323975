import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { MultiInfo } from './../../models/multi-info.model';
import { NgSelectItem } from './../../models/ng-select-item.model';

@Component({
  selector: 'ods-top-navbar-multi',
  templateUrl: './top-navbar-multi.component.html',
})
export class TopNavbarMultiComponent  implements OnChanges {
  @Input() public componentTitle = '';

  @Input() public logoSrc = 'assets/logo-inline-text-light.svg';
  @Input() public logoDescription = 'Onyx CenterSource';
  @Input() public logoRouterLink = '/';
  @Input() public helpRouterLink = '';
  @Input() public helpExternalLink = '';
  @Input() public logoSize = '';

  @Input() public userName = '';

  @Input() public multiInfo = {} as MultiInfo;

  @Output() public iataSelectedEmit: EventEmitter<string> = new EventEmitter();

  public selectedFormat = {} as NgSelectItem;
  public multiListFormat: NgSelectItem[] = [];
  public hasChanged = false;

  private firstPos = 1;
  private nextPos = 0;
  private noFoundPos = -1;

  constructor() {}


  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.multiInfo.currentValue) {
      const tmp = [] as NgSelectItem[];
      this.multiInfo = changes.multiInfo.currentValue;

      this.selectedFormat = {
        value: this.multiInfo.selected.agencyId,
        name: `${this.multiInfo.selected.agencyId} - ${this.multiInfo.selected.agencyName}`,
      } as NgSelectItem;

      this.multiInfo.multiList.forEach((val, i) => {
        const selectedFormatTemp = {
          value: val.agencyId,
          name: `${val.agencyId} - ${val.agencyName}`,
        };

        tmp.push(selectedFormatTemp);
      });

      // tslint:disable:max-line-length
      // tslint:disable:arrow-return-shorthand
      tmp.sort((x, y) => { return x.value === this.selectedFormat.value ? this.noFoundPos : y.value === this.selectedFormat.value ? this.nextPos : this.firstPos; });

      this.multiListFormat = [...tmp];

      console.log('top - ngOnChanges', this.selectedFormat);
      this.iataSelectedEmit.emit(this.selectedFormat.name);

    }
  }

  public onChange(event: NgSelectItem): void {
    this.hasChanged = true;
    if (event) {
      console.log('top - onChange', event);
      this.iataSelectedEmit.emit(event.name);
    }
  }

  /*public onBlur(): void {
    if (this.hasChanged && this.selectedFormat === null) {
      // To select the previous selected
      this.selectedFormat = {
        value: this.multiInfo.selected.agencyId,
        name: `${this.multiInfo.selected.agencyId} - ${this.multiInfo.selected.agencyName}`,
      } as NgSelectItem;
    }
  }*/

  public compareFn(d1: MultiInfo, d2: MultiInfo): boolean {
    return d1 && d2 ? d1.selected === d2.selected && d1.multiList === d2.multiList : d1 === d2;
  }

}
