import { NgModule, ModuleWithProviders } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { JwtModule, JwtHelperService } from '@auth0/angular-jwt';
import { CoreRoutingModule } from './core-routing.module';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AuthGuard } from './service/auth.guard';
import { AuthService } from './service/auth.service';
import { AuthStore } from './service/auth.store';
import { SpinnerService } from './service/spinner.service';
import { LoggingService } from './service/logging.service';
import { MessageService } from './service/message.service';
import { NavigationService } from './service/navigation.service';
import { RestService } from './service/rest.service';
import { ScrollService } from './service/scroll.service';
import { TestLoginService } from './service/test-login.service';
import { TitleService } from './service/title.service';
import { EnvironmentConfiguration} from './model/environment-configuration';
import { ENV_CONFIG } from './model/environment-configuration';
import { SpinnerInterceptor } from './utils/spinner.interceptor';
import { AuthHeaderInterceptor } from './utils/auth.interceptor';
import { FocusDirective } from './directive/focus.directive';
import { BookingSourcePipe } from './pipe/booking-source.pipe';
import { PayMethodPipe } from './pipe/pay-method.pipe';
import { ToStringPipe } from './pipe/to-string.pipe';
import { SafeUrlPipe} from './pipe/safe-url.pipe';
import { MoneyAmountComponent } from './component/money-amount/money-amount.component';
import { MoneyComponent } from './component/money/money.component';
import { MoneyCurrencyComponent } from './component/money-currency/money-currency.component';
import { AccessDeniedComponent } from './component/access-denied/access-denied.component';
import { AddressComponent } from './component/address/address.component';
import { FormPlaceholderComponent } from './component/form-placeholder/form-placeholder.component';
import { HeaderComponent } from './component/header/header.component';
import { InterstitialComponent } from './component/interstitial/interstitial.component';
import { MessageComponent } from './component/message/message.component';
import { NavComponent } from './component/nav/nav.component';
import { PageContentComponent} from './component/page-content/page-content.component';
import { PageHeaderComponent } from './component/page-header/page-header.component';
import { PageNotFoundComponent } from './component/page-not-found/page-not-found.component';
import { SignedOutComponent } from './component/signed-out/signed-out.component';
import { TestLoginComponent } from './component/test-login/test-login.component';

// pull general translations from the server
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}


@NgModule({

  imports: [
    BrowserModule,
    CoreRoutingModule,
    JwtModule.forRoot({}),
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  declarations: [
    MoneyAmountComponent,
    MoneyComponent,
    MoneyCurrencyComponent,
    FocusDirective,
    BookingSourcePipe,
    PayMethodPipe,
    ToStringPipe,
    SafeUrlPipe,
    AccessDeniedComponent,
    AddressComponent,
    FormPlaceholderComponent,
    HeaderComponent,
    InterstitialComponent,
    MessageComponent,
    NavComponent,
    PageContentComponent,
    PageHeaderComponent,
    PageNotFoundComponent,
    SignedOutComponent,
    TestLoginComponent
  ],
  exports: [
    MoneyAmountComponent,
    MoneyComponent,
    MoneyCurrencyComponent,
    FocusDirective,
    BookingSourcePipe,
    PayMethodPipe,
    ToStringPipe,
    SafeUrlPipe,
    AccessDeniedComponent,
    AddressComponent,
    FormPlaceholderComponent,
    HeaderComponent,
    InterstitialComponent,
    MessageComponent,
    NavComponent,
    PageContentComponent,
    PageHeaderComponent,
    PageNotFoundComponent,
    SignedOutComponent,
    TestLoginComponent
  ]
})
export class CoreModule {
  static forRoot(envConfig: EnvironmentConfiguration): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [
        { provide: ENV_CONFIG, useValue: envConfig },
        AuthGuard,
        AuthService,
        AuthStore,
        SpinnerService,
        JwtHelperService,
        LoggingService,
        MessageService,
        NavigationService,
        RestService,
        ScrollService,
        TestLoginService,
        TitleService,
        {
          provide: HTTP_INTERCEPTORS,
          useClass: SpinnerInterceptor,
          multi: true
        },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: AuthHeaderInterceptor,
          multi: true
        }
      ]
    };
  }
}
