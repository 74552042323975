import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { take } from 'rxjs/internal/operators';
import { ENV_CONFIG, EnvironmentConfiguration } from '../../model';
import { AuthService, LoggingService, TestLoginResponse, TestLoginService, TitleService } from '../../service';

const SHOW_NONE = 'none';
const SHOW_ALL = 'all';
const SHOW_SUPPORT = 'support';

@Component({
  selector: 'core-test-login',
  templateUrl: './test-login.component.html'
})
export class TestLoginComponent implements OnInit {

  public pageTitle = 'testLogins';

  constructor(
    private authService: AuthService,
    private loggingService: LoggingService,
    private testLoginService: TestLoginService,
    private titleService: TitleService,
    private router: Router,
    @Inject(ENV_CONFIG) private envConfig: EnvironmentConfiguration
  ) { }

  ngOnInit() {
  }

  public get showTestLogins(): boolean {
    return this.envConfig.showTestLogins !== SHOW_NONE;
  }

  public get showAllLogins(): boolean {
    return this.envConfig.showTestLogins === SHOW_ALL;
  }

  public get showSupportLogin(): boolean {
    return this.envConfig.showTestLogins === SHOW_SUPPORT;
  }

  public propertyTestUser(): void {
    this.testLoginService.propertyTestUser()
      .pipe(take(1))
      .subscribe((s: TestLoginResponse) => this.handleSuccess(s));
  }

  public brandTestUser(): void {
    this.testLoginService.brandTestUser()
      .pipe(take(1))
      .subscribe((s: TestLoginResponse) => this.handleSuccess(s));
  }

  public multiBrandTestUser(): void {
    this.testLoginService.multiBrandTestUser()
      .pipe(take(1))
      .subscribe((s: TestLoginResponse) => this.handleSuccess(s));
  }

  public chainTestUser(): void {
    this.testLoginService.chainTestUser()
      .pipe(take(1))
      .subscribe((s: TestLoginResponse) => this.handleSuccess(s));
  }

  public agencyTestUser(): void {
    this.testLoginService.agencyTestUser()
      .pipe(take(1))
      .subscribe((s: TestLoginResponse) => this.handleSuccess(s));
  }

  public paylocTestUser(): void {
    this.testLoginService.paylocTestUser()
      .pipe(take(1))
      .subscribe((s: TestLoginResponse) => this.handleSuccess(s));
  }

  public customerTestUser(): void {
    this.testLoginService.customerTestUser()
      .pipe(take(1))
      .subscribe((s: TestLoginResponse) => this.handleSuccess(s));
  }

  public supportTestUser(): void {
    this.testLoginService.supportTestUser()
      .pipe(take(1))
      .subscribe((s: TestLoginResponse) => this.handleSuccess(s));
  }

  private handleSuccess(s: TestLoginResponse): void {
    const rawToken = s.jwtToken;
    const helper = new JwtHelperService();
    const decodedToken = helper.decodeToken(rawToken);

    this.loggingService.logInfo('Authenticating with test token...');

    this.router.navigate(
      ['/'],
      {
        queryParams:
        {
          Authorization: rawToken
        }
      }
    );
  }
}
