import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import * as _ from 'lodash';
import { Observable, of } from 'rxjs';
import { mergeMap, timeout } from 'rxjs/operators';
import { ENV_CONFIG, EnvironmentConfiguration } from '../model';
import { LoggingService } from './logging.service';
import { AuthStore } from './auth.store';

const REST_HEADERS = {
  'Content-Type': 'application/json',
  Authorization: ''
};

export enum RESPONSE_TYPE {
  JSON = 'json',
  ARRAY_BUFFER = 'arraybuffer',
  BLOB = 'blob',
  TEXT = 'text'
}

@Injectable({
  providedIn: 'root'
})
export class RestService {

  constructor(
    private http: HttpClient,
    private loggingService: LoggingService,
    private authStore: AuthStore,
    @Inject(ENV_CONFIG) private envConfig: EnvironmentConfiguration
  ) { }

  public getVariablePath(path: string, tokens: {}): string {
    _.forEach(tokens, (value, key) => {
        path = path.replace('{' + key + '}', value);
    });
    return path;
}

public getEntity<T>(relativePath: string, bodyTransformer?: (body: any) => T): Observable<T> {
    const restEndpoint = this.getRestEndpoint(relativePath);
    const call = this.http.get(restEndpoint, this.getRestHeaders());
    return this.processResponse<T>(call, bodyTransformer);
}

public postEntity<T>(relativePath: string, data: any, bodyTransformer?: (body: any) => T): Observable<T> {
    const restEndpoint = this.getRestEndpoint(relativePath);
    this.loggingService.logInfo(JSON.stringify(this.getRestHeaders()));
    const call = this.http.post(restEndpoint, JSON.stringify(data), this.getRestHeaders());
    return this.processResponse<T>(call, bodyTransformer);
}

public postEntityCustomResponseType<T>(relativePath: string, data: any, responseType?: RESPONSE_TYPE,
                                       bodyTransformer?: (body: any) => T): Observable<T> {
    const restEndpoint = this.getRestEndpoint(relativePath);
    const call = this.http.post(restEndpoint, JSON.stringify(data), this.getRestHeaders(responseType));
    return this.processResponse<T>(call, bodyTransformer);
}

private getRestEndpoint(relativePath: string): string {
    return `${this.envConfig.serviceUrl}${relativePath}`;
}

private getRestHeaders(responseType?: RESPONSE_TYPE): any {
    const authHeaders = REST_HEADERS;
    authHeaders.Authorization = _.isNil(this.authStore.getToken()) ? '' : this.authStore.getToken();
    const restHeaders = { headers: _.clone(authHeaders) };
    if (!_.isNil(responseType)) {
      restHeaders['responseType'] = responseType;
    }
    return restHeaders;
}

private processResponse<T>(call: any, bodyTransformer?: (body: any) => T): Observable<T> {
    const bodyHandler = (body: any) => {
        this.loggingService.logInfo(JSON.stringify(body, null, 2));

        if (!_.isNil(bodyTransformer)) {
            return of(bodyTransformer(body));
        }

        return of(body as T);
    };

    return call.pipe(
        mergeMap(bodyHandler),
        timeout(this.envConfig.requestTimeout)
    );
}

}
