<div class="container" *ngIf="showTestLogins">
  <div *ngIf="showAllLogins">
    <p><button type="button" id="propertyTestUserLoginLink" class="btn btn-link btn-sm" (click)="propertyTestUser()">Login as property-level user</button></p>
    <p><button type="button" id="brandTestUserLoginLink" class="btn btn-link btn-sm" (click)="brandTestUser()">Login as brand-level user</button></p>
    <p><button type="button" id="multiBrandTestUserLoginLink" class="btn btn-link btn-sm" (click)="multiBrandTestUser()">Login as multi brand-level user</button></p>
    <p><button type="button" id="chainTestUserLoginLink" class="btn btn-link btn-sm" (click)="chainTestUser()">Login as chain-level user</button></p>
    <p><button type="button" id="agencyTestUserLoginLink" class="btn btn-link btn-sm" (click)="agencyTestUser()">Login as agency-level user</button></p>
    <p><button type="button" id="paylocTestUserLoginLink" class="btn btn-link btn-sm" (click)="paylocTestUser()">Login as payloc-level user</button></p>
    <p><button type="button" id="customerTestUserLoginLink" class="btn btn-link btn-sm" (click)="customerTestUser()">Login as customer-level user</button></p>
  </div>
  <p *ngIf="showAllLogins || showSupportLogin">
    <button type="button" class="btn btn-link btn-sm" (click)="supportTestUser()">Login as support user</button>
  </p>
</div>