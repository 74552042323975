import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AuthService } from '../service/auth.service';

@Injectable()
export class AuthHeaderInterceptor implements HttpInterceptor {

  constructor(
    private authService: AuthService
  ) { }

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      tap(
        response => {
          if (response instanceof HttpResponse) {
            const newToken = response.headers.get('Authorization');
            if (newToken && newToken !== '') {
              this.authService.refreshToken(newToken);
            }
          }
        },
        error => {
          // do nothing, request errors are handled elsewhere
        }
      )
    );
  }

}
