import { Injectable } from '@angular/core';
import { ActiveToast, IndividualConfig, ToastrService } from 'ngx-toastr';
import { AlertToastComponent } from '../../components';

@Injectable({
  providedIn: 'root',
})
/**
 * @deprecated This service is deprecated; use `ngx-toastr` directly or a library appropriate to your project's architectural standards
 */
export class AlertToastService {
  constructor(
    private toastr: ToastrService,
  ) { }

  public success(message?: string, hasIcon?: boolean, override: Partial<IndividualConfig> = {}): void {
    const activeToast = this.toastr.success(message, undefined, override);
    this.setIcon(activeToast, hasIcon);
  }

  public info(message?: string, hasIcon?: boolean, override: Partial<IndividualConfig> = {}): void {
    const activeToast = this.toastr.info(message, undefined, override);
    this.setIcon(activeToast, hasIcon);
  }

  public warning(message?: string, hasIcon?: boolean, override: Partial<IndividualConfig> = {}): void {
    const activeToast = this.toastr.warning(message, undefined, override);
    this.setIcon(activeToast, hasIcon);
  }

  public danger(message?: string, hasIcon?: boolean, override: Partial<IndividualConfig> = {}): void {
    const activeToast = this.toastr.error(message, undefined, override);
    this.setIcon(activeToast, hasIcon);
  }

  private setIcon(activeToast: ActiveToast<unknown>, hasIcon: boolean = false): void {
    if (activeToast && hasIcon) {
      const component: AlertToastComponent = <AlertToastComponent>activeToast.toastRef.componentInstance;
      component.hasIcon.next(hasIcon);
    }
  }
}
