import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { AUTHORIZATION_PARAMETER } from '../utils/constants';
import { AuthService } from './auth.service';
import { NavigationService } from './navigation.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(
    private authService: AuthService,
    private navService: NavigationService
  ) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

    let token = next.queryParams[AUTHORIZATION_PARAMETER];

    if (!token) {
      token = next.queryParams[AUTHORIZATION_PARAMETER.toLowerCase()];
    }

    if (token) {
      this.authService.login(token);
    }

    if (!this.authService.isUserLoggedIn()) {
      this.navService.goToAccessDenied();
      return false;
    }

    return true;
  }
}
