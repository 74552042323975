import { Inject, Injectable } from '@angular/core';
import { ENV_CONFIG } from '../model/environment-configuration';
import { EnvironmentConfiguration } from '../model/environment-configuration';
import { JWT_TOKEN_STORAGE_KEY } from '../utils/constants';

@Injectable({
  providedIn: 'root'
})
export class JwtCoreService {

  constructor(
    @Inject(ENV_CONFIG) private envConfig: EnvironmentConfiguration
  ) { }

  getTokenGetter() {
    return localStorage.getItem(JWT_TOKEN_STORAGE_KEY);
  }

  getWhitelistedDomains() {
    return this.removeProtocol(this.envConfig.serviceUrl);
  }

  removeProtocol(urlString: string) {
    return urlString.split('://')[1];
  }
}
