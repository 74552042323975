import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { NgSelectModule } from '@ng-select/ng-select';
import { AlertModule } from 'ngx-bootstrap/alert';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { ModalModule } from 'ngx-bootstrap/modal';
import { ToastContainerModule, ToastrModule } from 'ngx-toastr';
import { AlertInlineComponent } from './components/alert-inline/alert-inline.component';
import { AlertToastContainerComponent } from './components/alert-toast-container/alert-toast-container.component';
import { AlertToastComponent } from './components/alert-toast/alert-toast.component';
import { DatePickerComponent } from './components/date-picker/date-picker.component';
import { DateRangePickerComponent } from './components/date-range-picker/date-range-picker.component';
import { DropdownButtonComponent } from './components/dropdown-button/dropdown-button.component';
import { FooterComponent } from './components/footer/footer.component';
import { LeftNavbarListItemComponent } from './components/left-navbar-list-item/left-navbar-list-item.component';
import { LeftNavbarComponent } from './components/left-navbar/left-navbar.component';
import { SessionTimeoutComponent } from './components/session-timeout/session-timeout.component';
import { TopNavbarMultiComponent } from './components/top-navbar-multi/top-navbar-multi.component';
import { TopNavbarComponent } from './components/top-navbar/top-navbar.component';
import { ViewContentComponent } from './components/view-content/view-content.component';
import { AlertToastService } from './services/alert-toast/alert-toast.service';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    AlertModule.forRoot(),
    BsDatepickerModule.forRoot(),
    ToastrModule.forRoot({
      positionClass: 'inline',
      toastComponent: AlertToastComponent,
      timeOut: 4000,
      enableHtml: true,
    }),
    ToastContainerModule,
    NgSelectModule,
    ModalModule,
    NgIdleKeepaliveModule.forRoot(),
  ],
  entryComponents: [
    AlertToastComponent,
  ],
  declarations: [
    AlertInlineComponent,
    AlertToastComponent,
    AlertToastContainerComponent,
    DatePickerComponent,
    DateRangePickerComponent,
    DropdownButtonComponent,
    LeftNavbarListItemComponent,
    LeftNavbarComponent,
    TopNavbarComponent,
    TopNavbarMultiComponent,
    ViewContentComponent,
    FooterComponent,
    SessionTimeoutComponent,
  ],
  exports: [
    AlertInlineComponent,
    AlertToastComponent,
    AlertToastContainerComponent,
    DatePickerComponent,
    DateRangePickerComponent,
    DropdownButtonComponent,
    LeftNavbarListItemComponent,
    LeftNavbarComponent,
    TopNavbarComponent,
    TopNavbarMultiComponent,
    ViewContentComponent,
    FooterComponent,
    SessionTimeoutComponent,
  ],
  providers: [
    AlertToastService,
  ],
})
export class NgxDesignSystemComponentsModule { }
