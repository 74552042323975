<core-page-header type='warning'></core-page-header>

<div class="row mt-5">
  <div class="card-content">
    <div class="card-watermark">
      <h3>{{pageTitle}}</h3>
      <figure class="figure">
        <i class="fal fa-lock figure-img"></i>
        <figcaption class="figure-caption">
          {{'app.accessDenied.pleasePart' | translate}} <a href="{{hrefTarget}}" translate>app.accessDenied.loginPart</a> {{'app.accessDenied.accessPart' | translate}},
          <br />
          {{'app.contactSupportTeam' | translate}}
        </figcaption>
      </figure>
    </div>
  </div>

  <!-- TODO: Remove this entirely -->
  <core-test-login *ngIf="allowLoginOverride"></core-test-login>
</div>