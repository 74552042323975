import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/internal/operators';
import { TitleService } from '../../service';

@Component({
  selector: 'core-page-header',
  templateUrl: './page-header.component.html'
})
export class PageHeaderComponent implements OnInit, OnDestroy {

  @Input() public title = '';
  @Input() public type = '';

  private unsubscribe: Subject<void> = new Subject<void>();

  constructor(
    private titleService: TitleService
  ) { }

  ngOnInit() {
    this.titleService.title
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((newTitle: string) => this.title = newTitle);

    this.titleService.type
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((newType: string) => this.type = newType);
  }

  public ngOnDestroy(): void {
    this.unsubscribe.next();
  }

}
