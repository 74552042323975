import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

export const CoreRoutes: Routes = [];

@NgModule({
  imports: [
    RouterModule.forChild(CoreRoutes)
  ],
  declarations: [],
  exports: [
    RouterModule
  ]
})
export class CoreRoutingModule { }
