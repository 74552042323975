import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ScrollService {

  constructor() { }

  public scrollToTop(): void {
    // https://blog.lysender.com/2018/07/angular-6-add-scroll-to-top-when-route-changes/
    // https://auralinna.blog/post/2018/scroll-to-top-on-angular-route-change
    window.scroll(0, 0);
  }

}
