import { Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/internal/operators';
import { InterstitialService } from '../../service/interstitial.service';

@Component({
  selector: 'core-interstitial',
  templateUrl: './interstitial.component.html'
})
export class InterstitialComponent implements OnInit, OnDestroy {

  public message: string = null;

  private unsubscribe: Subject<void> = new Subject();

  constructor(
    private element: ElementRef,
    private interstitialService: InterstitialService
  ) { }

  public ngOnInit(): void {
    this.interstitialService.interstitial
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((message: string) => {
        // NOTE: This logic has been wrapped to eliminate the ExpressionChangedAfterItHasBeenCheckedError
        // tslint:disable-next-line:max-line-length
        // https://blog.angularindepth.com/everything-you-need-to-know-about-the-expressionchangedafterithasbeencheckederror-error-e3fd9ce7dbb4
        // TODO: Resolve this timing error without setTimeout()
        setTimeout(() => {
          const spinnerStateClass = !!message ? 'spinner-open' : '';

          this.message = message;

          // TODO: Remove the DOM traversal in favor of an Angular-ized solution
          this.element.nativeElement.closest('body').className = spinnerStateClass;
        });
      });
  }

  public ngOnDestroy(): void {
    this.unsubscribe.next();
  }

  public clickCancel(event): void {
    event.preventDefault();
    this.interstitialService.cancel();
  }

}
