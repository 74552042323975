import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'core-money-amount',
  templateUrl: './money-amount.component.html'
})
export class MoneyAmountComponent implements OnInit {

  @Input() amount: number;

  constructor() { }

  ngOnInit() {
  }

}
