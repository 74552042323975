import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'core-money-currency',
  templateUrl: './money-currency.component.html'
})
export class MoneyCurrencyComponent implements OnInit {

  @Input() currency: string;
  @Input() currencyDescription: string;
  @Input() tooltipPlacement = 'bottom';
  @Input() showDescription = true;

  constructor() { }

  ngOnInit() {
  }

}
