<core-page-header></core-page-header>

<div class="row mt-5">
  <div class="card-content">
    <div class="card-watermark">
      <h3>{{pageTitle}}</h3>
      <figure class="figure">
        <i class="fal fa-check-circle figure-img"></i>
        <figcaption class="figure-caption">
          {{'app.signedOut.thanks' | translate}} <a href="/" translate>app.title</a>
        </figcaption>
      </figure>
    </div>
  </div>
</div>