import { ActiveFilter } from './active-filter';
import { BasicInput } from './basic-input';

export class BasicActiveFilter implements ActiveFilter {
  public value: any = null;
  public type: string = null;

  public constructor(public name: string, input: any, type?: string) {
    this.value = input[this.name];
    this.type = type;
  }

  // by default we simply try to delete the named property from the incoming
  // transaction search input, subclasses can do their own thing
  public onClick = (input: BasicInput) => {
    delete input[this.name];
  }

  public isExcluded(): boolean {
    return false;
  }

  public getDisplayValue(): string {
    return this.value;
  }

  public getType(): string {
    return this.type;
  }
}
