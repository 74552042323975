import { Component, Input, OnInit } from '@angular/core';
import { AlertInlineTypes } from '../../models/alert-inline-types.enum';

@Component({
  selector: 'ods-alert-inline',
  templateUrl: './alert-inline.component.html',
})
export class AlertInlineComponent implements OnInit {
  @Input() public get type(): AlertInlineTypes {
    return this._type;
  }

  public set type(value: AlertInlineTypes) {
    this._type = value;

    this.toggleAlertIcon();
  }

  @Input() public componentTitle = '';

  @Input() public dismissible = false;
  @Input() public timeout = '';
  @Input() public hasIcon = false;

  /**
   * @deprecated The `title` property is deprecated; use `componentTitle`
   */
  @Input() public get title(): string {
    console.warn('The "title" property is deprecated; use "componentTitle"');

    return this.componentTitle;
  }

  /**
   * @deprecated The `title` property is deprecated; use `componentTitle`
   */
  public set title(value: string) {
    console.warn('The "title" property is deprecated in favor of "componentTitle"');

    this.componentTitle = value;
  }

  public iconClass = '';

  private _type!: AlertInlineTypes;

  constructor() { }

  public ngOnInit(): void {
    this.toggleAlertIcon();
  }

  public toggleAlertIcon(): void {
    switch (this.type) {
      case AlertInlineTypes.success:
      case AlertInlineTypes.primary:
        this.iconClass = 'fa-check';
        break;
      case AlertInlineTypes.info:
        this.iconClass = 'fa-pennant';
        break;
      case AlertInlineTypes.warning:
        this.iconClass = 'fa-hand-paper';
        break;
      case AlertInlineTypes.danger:
        this.iconClass = 'fa-exclamation-triangle';
        break;
      default:
        this.iconClass = '';
        break;
    }
  }
}
