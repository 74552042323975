<core-page-header type='warning'></core-page-header>

<div class="row mt-5">
  <div class="card-content">
    <div class="card-watermark">
      <h3>{{pageTitle}}</h3>
      <figure class="figure">
        <i class="glyph-pages figure-img"></i>
        <figcaption class="figure-caption">
          {{'app.pageNotFound.contactPart' | translate}}
          <br />
          {{'app.pageNotFound.visitPart' | translate}} <a href="/" translate>app.title</a> {{'app.pageNotFound.explorePart' | translate}}
        </figcaption>
      </figure>
    </div>
  </div>
</div>