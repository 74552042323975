import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'core-money',
  templateUrl: './money.component.html'
})
export class MoneyComponent implements OnInit {

  @Input() amount: number;
  @Input() currency: string;
  @Input() currencyDescription: string;
  @Input() tooltipPlacement = 'bottom';

  constructor() { }

  ngOnInit() {
  }

}
