import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MenuService {

  public LARGE_SIZE = 1008;

  public isExpandedMenu$: Observable<boolean>;
  public isClickeMenu$: Observable<boolean>;

  private isExpandedMenuSubject: BehaviorSubject<boolean>;
  private isClickedMenuSubject: BehaviorSubject<boolean>;

  constructor() {
    if (window.innerWidth > this.LARGE_SIZE) {
      this.isExpandedMenuSubject = new BehaviorSubject<boolean>(true);
    } else {
      this.isExpandedMenuSubject = new BehaviorSubject<boolean>(false);
    }
    this.isExpandedMenu$ = this.isExpandedMenuSubject.asObservable();

    this.isClickedMenuSubject = new BehaviorSubject<boolean>(true);
    this.isClickeMenu$ = this.isClickedMenuSubject.asObservable();
  }

  public setIsExpandedMenu(value: boolean): void {
    this.isExpandedMenuSubject.next(value);
  }

  public getIsExpandedMenu(): boolean {
    return this.isExpandedMenuSubject.value;
  }

  public setIsClickedMenu(value: boolean): void {
    this.isClickedMenuSubject.next(value);
  }

  public getIsClickedMenu(): boolean {
    return this.isClickedMenuSubject.value;
  }



}
