import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'core-page-content',
  templateUrl: './page-content.component.html'
})
export class PageContentComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
