import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';

@Pipe({
  name: 'bookingSource'
})
export class BookingSourcePipe implements PipeTransform {

  constructor(
    private translateService: TranslateService
  ) { }

  transform(value: any) {
    const keyValue = 'booking.source.' + value;
    let bookingSource = value;
    this.translateService.get(keyValue).pipe(take(1))
      .subscribe(newValue => {
        if (newValue === keyValue) {
          bookingSource = value;
        } else {
          bookingSource = newValue;
        }
      });
    return bookingSource;
  }

}
