import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';

const MESSAGE = 'Loading ...';

@Injectable({
  providedIn: 'root'
})
export class SpinnerService {
  isLoading = new Subject<boolean>();
  message = new BehaviorSubject(MESSAGE);

  public show() {
    this.isLoading.next(true);
  }

  public hide() {
    this.isLoading.next(false);
  }

  public setMessage(msg: string): void {
    this.message.next(msg);
  }

}
