import { Component, Input } from '@angular/core';

@Component({
  selector: 'ods-top-navbar',
  templateUrl: './top-navbar.component.html',
})
export class TopNavbarComponent {
  @Input() public componentTitle = '';

  @Input() public logoSrc = 'assets/logo-inline-text-light.svg';
  @Input() public logoDescription = 'Onyx CenterSource';
  @Input() public logoRouterLink = '/';
  @Input() public helpRouterLink = '';
  @Input() public helpExternalLink = '';
  @Input() public logoSize = '';
  @Input() public userName = '';
}
