import { Component, Input, OnInit } from '@angular/core';
import { Address } from '../../model';

@Component({
  selector: 'core-address',
  templateUrl: './address.component.html'
})
export class AddressComponent implements OnInit {

  @Input() public address: Address;
  @Input() public id: string;

  constructor() { }

  ngOnInit() {
  }

}
