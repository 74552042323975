import { Injectable } from '@angular/core';
import { JWT_TOKEN_STORAGE_KEY } from '../utils/constants';

@Injectable({
  providedIn: 'root'
})
export class AuthStore {

  constructor() { }

  setToken(token: string) {
    localStorage.setItem(JWT_TOKEN_STORAGE_KEY, token);
  }

  getToken() {
    return localStorage.getItem(JWT_TOKEN_STORAGE_KEY);
  }

  clearToken() {
    localStorage.removeItem(JWT_TOKEN_STORAGE_KEY);
  }
}
