import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/internal/operators';
import { TitleService } from '../../service';

const PAGE_NOT_FOUND_TITLE_KEY = 'app.pageNotFound.title';
const WARNING_CLASS_KEY = 'warning';

@Component({
  selector: 'core-page-not-found',
  templateUrl: './page-not-found.component.html'
})
export class PageNotFoundComponent implements OnInit {

  public pageTitle = '';

  constructor(
    private titleService: TitleService,
    private translate: TranslateService
  ) { }

  ngOnInit() {
    this.translate.get(PAGE_NOT_FOUND_TITLE_KEY).pipe(take(1)).subscribe(title => {
      this.pageTitle = title;
      this.titleService.setTitle(title, WARNING_CLASS_KEY);
    });
  }

}
