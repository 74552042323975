import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MenuProp } from '../../models/menu-prop.model';
import { MenuService } from '../../services/menu/menu.service';

@Component({
  selector: 'ods-left-navbar-list-item',
  templateUrl: './left-navbar-list-item.component.html',
})
export class LeftNavbarListItemComponent implements OnInit {
  @Input() public get label(): string {
    return this._label;
  }

  public set label(value: string) {
    this._label = value;
    this.updateTooltip();
  }

  @Input() public get caption(): string {
    return this._caption;
  }

  public set caption(value: string) {
    this._caption = value;
    this.updateTooltip();
  }

  @Input() public routerLink = '';
  @Input() public externalLink = '';
  @Input() public routerLinkActiveOptions: { exact: boolean } = { exact: true };

  /**
   * @deprecated The `iconClass` property is deprecated; use `iconIndicator` or `altIndicator`
   */
  @Input() public get iconClass(): string {
    return this.iconIndicator;
  }

  /**
   * @deprecated The `iconClass` property is deprecated; use `iconIndicator` or `altIndicator`
   */
  public set iconClass(value: string) {
    this.iconIndicator = value;
  }

  @Input() public iconIndicator = '';
  @Input() public altIndicator = '';
  @Input() public warningTooltip = '';

  public tooltip = '';

  @Output('click') public listItemClickEventEmitter: EventEmitter<MenuProp> = new EventEmitter<MenuProp>();

  private _label = '';
  private _caption = '';

  constructor(private menuService: MenuService) { }

  public ngOnInit(): void {
    this.updateTooltip();
  }

  public hasIndicator(): boolean {
    return !!this.iconIndicator || !!this.altIndicator;
  }

  public onListItemClicked(event: Event): void {
    this.menuService.setIsExpandedMenu(false);
    this.menuService.setIsClickedMenu(false);
    this.listItemClickEventEmitter.emit({isExpanded: false, isClicked: false});
  }

  private updateTooltip(): void {
    if (this.label && this.caption) {
      this.tooltip = `${this.label} ${this.caption}`;
    } else {
      this.tooltip = this.label;
    }
  }
}
