import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import * as _ from 'lodash';
import { BehaviorSubject } from 'rxjs';
import { Token, User } from '../model';
import { AuthStore } from './auth.store';

export const GUEST_USER: User = {};

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private userAuthenticated: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(
    private authStore: AuthStore,
    private jwtHelper: JwtHelperService
  ) { }

  public user(): User {
    const token: Token = this.jwtHelper.decodeToken(this.authStore.getToken());

    if (_.isNil(token)) {
      return GUEST_USER;
    }

    return {
      username: token.jti,
      email: token.email
    } as User;
  }

  public isUserLoggedIn(): boolean {
    this.updateState();
    return this.userAuthenticated.getValue();
  }

  private updateState() {
    this.userAuthenticated.next(this.isTokenValid());
  }

  public login(token: string) {
    this.authStore.setToken(token);
    this.updateState();
  }

  public refreshToken(token: string) {
    this.authStore.setToken(token);
  }

  public logout() {
    this.authStore.clearToken();
    this.updateState();
  }

  private isTokenExpired(): boolean {
    const token = this.authStore.getToken();

    if (!token) {
      return true;
    }

    return this.jwtHelper.isTokenExpired(token);
  }

  private isTokenValid(): boolean {
    const isTokenValid = !this.isTokenExpired();
    // TODO is this enough validation?
    return isTokenValid;
  }
}
