import { Component, OnDestroy, OnInit } from '@angular/core';
import * as _ from 'lodash';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/internal/operators';
import { Message } from '../../model/message';
import { MessageService } from '../../service/message.service';

@Component({
  selector: 'core-message',
  templateUrl: './message.component.html'
})
export class MessageComponent implements OnInit, OnDestroy {

  public showing = false;
  public type: string;
  public messages: string[] = [];

  private unsubscribe: Subject<void> = new Subject<void>();

  constructor(
    public messageService: MessageService
  ) { }

  public ngOnInit(): void {
    this.messageService.message
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((msg: Message) => {
        if (!_.isNil(msg)) {
          this.showing = true;
          this.type = msg.type;
          this.messages = msg.messages;
        } else {
          this.messages = [];
        }

        this.toggleMessageContainer();
      });
  }

  public ngOnDestroy(): void {
    this.unsubscribe.next();
  }

  public closeClicked(message: string): void {
    this.removeMessage(message);
  }

  private removeMessage(message: string): void {
    const messageIndex: number = this.messages.indexOf(message);

    if (messageIndex >= 0) {
      this.messages.splice(messageIndex, 1);
    }

    this.toggleMessageContainer();
  }

  private toggleMessageContainer(): void {
    this.showing = this.messages && this.messages.length > 0;
  }

}
