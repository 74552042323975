import { CommonModule } from '@angular/common';
import { HttpClientTestingModule } from '@angular/common/http/testing';
import { TestBed, async } from '@angular/core/testing';
import { FormsModule } from '@angular/forms';
import { RouterTestingModule } from '@angular/router/testing';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { } from 'jasmine';
import * as _ from 'lodash';
import { Observable, of } from 'rxjs';

export function getTranslateModule(translations?: any): any {
  class FakeLoader implements TranslateLoader {
    getTranslation(lang: string): Observable<any> {
      return of(translations || {});
    }
  }

  return TranslateModule.forRoot({
    loader: {
      provide: TranslateLoader,
      useClass: FakeLoader
    }
  });
}

export function configureTestingModule(testingModuleConfig?: any) {
  const baseConfig = {
    imports: [
      CommonModule,
      FormsModule,
      HttpClientTestingModule,
      RouterTestingModule,
      getTranslateModule()
    ]
  };

  TestBed.configureTestingModule(_.merge(baseConfig, testingModuleConfig)).compileComponents();
}

export function createTestingComponent<T>(componentType: any, initComponent?: (c: T) => void): any {
  const target = {
    fixture: TestBed.createComponent<T>(componentType),
    component: null
  };

  target.component = target.fixture.componentInstance as T;

  if (!_.isNil(initComponent)) {
    initComponent(target.component);
  }

  target.fixture.detectChanges();

  return target;
}

export function basicComponentTest<T>(componentType: any, testingModuleConfig?: any, initComponent?: (c: T) => void) {
  let testingComponent = null;

  testingModuleConfig = testingModuleConfig || {};

  const baseModuleConfig = {
    declarations: testingModuleConfig.declarations || [componentType]
  };

  beforeEach(async(() => {
    configureTestingModule(_.merge(baseModuleConfig, testingModuleConfig));
  }));

  beforeEach(async(() => {
    testingComponent = createTestingComponent<T>(componentType, initComponent);
  }));

  it('should create', () => {
    expect(testingComponent.component).not.toBeNull();
  });
}
