<div class="container-fluid">
  <div class="row app-area">
    <core-nav>
      <ng-content></ng-content>
    </core-nav>
    <div class="content-area">
      <div class="content-container">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</div>