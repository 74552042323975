import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/internal/operators';
import { AuthService, TitleService } from '../../service';

const SIGNED_OUT_TITLE_KEY = 'app.signedOut.title';

@Component({
  selector: 'core-signed-out',
  templateUrl: './signed-out.component.html'
})
export class SignedOutComponent implements OnInit {

  public pageTitle = '';

  constructor(
    private authService: AuthService,
    private titleService: TitleService,
    private translate: TranslateService
  ) { }

  ngOnInit() {
    this.authService.logout();

    this.translate.get(SIGNED_OUT_TITLE_KEY).pipe(take(1)).subscribe(title => {
      this.pageTitle = title;
      this.titleService.setTitle(title);
    });
  }

}
