import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { BsDatepickerConfig, BsDatepickerDirective } from 'ngx-bootstrap/datepicker';

@Component({
  selector: 'ods-date-picker',
  templateUrl: './date-picker.component.html',
})
export class DatePickerComponent implements OnInit {
  public readonly defaultDateInputFormat: string = 'MM/DD/YYYY';

  @Input() public get selectedDate(): Date {
    return this._selectedDate;
  }

  public set selectedDate(value: Date) {
    this._selectedDate = value;

    this.selectedDateChangeEventEmitter.emit(this._selectedDate);
  }

  @Input() public get dateInputFormat(): string {
    return this._dateInputFormat;
  }

  public set dateInputFormat(value: string) {
    this._dateInputFormat = value;

    this.updateDateInputFormat();
  }

  @Input() public disabled = false;
  @Input() public placeholderText = this.defaultDateInputFormat;
  @Input() public minimumDate!: Date;
  @Input() public maximumDate!: Date;
  @Input() public isValid = true;

  @ViewChild(BsDatepickerDirective) public datePicker!: BsDatepickerDirective;

  public configuration: Partial<BsDatepickerConfig> = {
    showWeekNumbers: false,

    // Source CSS classes are defined in
    // src/styles/_datepickers.scss
    containerClass: 'theme-ods',
    customTodayClass: 'today',

    // Any moment.js format
    // https://valor-software.com/ngx-bootstrap/#/datepicker#format
    dateInputFormat: this.dateInputFormat,
  };

  // This Input/Output pairing follows the convention for creating two-way binding
  // https://blog.angulartraining.com/tutorial-create-your-own-two-way-data-binding-in-angular-46487650ea82
  @Output('selectedDateChange') public selectedDateChangeEventEmitter: EventEmitter<Date> = new EventEmitter();

  private _selectedDate!: Date;
  private _dateInputFormat: string = this.defaultDateInputFormat;

  public ngOnInit(): void {
    this.updateDateInputFormat();
  }

  public onDateButtonClicked(): void {
    console.log('onDateButtonClicked');
    this.datePicker.show();
  }

  private updateDateInputFormat(): void {
    if (this.configuration) {
      this.configuration.dateInputFormat = this.dateInputFormat;
    }
  }
}
