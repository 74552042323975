import { ActiveFilter } from './active-filter';
import { BasicInput } from './basic-input';
import { InputToRequestTransformerGeneric } from '../utils/input-to-request-transformer-generic';

export class BasicActiveDateFilter implements ActiveFilter {
  public value: any = null;
  public type: string = null;
  public constructor(public name: string, input: any, type?: string) {
    this.value = new InputToRequestTransformerGeneric().formatDateInputString(input[this.name]);
    this.type = type;
  }

  public onClick = (input: BasicInput) => {
    delete input[this.name];
  }

  public isExcluded(): boolean {
    return false;
  }

  public getDisplayValue(): string {
    return this.value;
  }
  public getType(): string {
    return this.type;
  }
}
