import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'core-form-placeholder',
  templateUrl: './form-placeholder.component.html'
})
export class FormPlaceholderComponent implements OnInit {

  @Input() text: string;
  @Input() placeholderText = 'app.form.placeholder';

  constructor() { }

  ngOnInit() {
  }

}
