import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Message } from '../model';

const ERROR_TYPE = 'danger';

@Injectable({
  providedIn: 'root'
})
export class MessageService {

  private messageSubject = new BehaviorSubject<Message>(null);

  public message = this.messageSubject.asObservable();

  public showErrorMessage(message: string) {
    this.showErrorMessages([message]);
  }

  public showErrorMessages(messages: string[]) {
    this.messageSubject.next({
      type: ERROR_TYPE,
      messages: messages,
    });
  }

  public clearMessages() {
    this.messageSubject.next(null);
  }

}
