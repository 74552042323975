import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FooterInfo } from '../../models/footer-info.model';

@Component({
  selector: 'ods-footer',
  templateUrl: './footer.component.html',
})
export class FooterComponent implements OnChanges {

  @Input() public footerInfo = {} as FooterInfo;

  public mailMe = '';
  public copyrightYear = new Date().getFullYear();

  constructor() { }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.footerInfo) {
      this.mailMe = `mailto:${changes.footerInfo.currentValue.email}`;
    }
  }


}
