import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { Toast, ToastPackage, ToastrService } from 'ngx-toastr';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'ods-alert-toast',
  templateUrl: './alert-toast.component.html',
  animations: [
    trigger('flyInOut', [
      state('inactive', style({ opacity: 0 })),
      state('active', style({ opacity: 1 })),
      state('removed', style({ opacity: 0 })),
      transition(
        'inactive => active',
        animate('{{ easeTime }}ms {{ easing }}'),
      ),
      transition(
        'active => removed',
        animate('{{ easeTime }}ms {{ easing }}'),
      ),
    ]),
  ],
  preserveWhitespaces: false,
})
export class AlertToastComponent extends Toast implements OnInit {
  public iconClasses: string[] = [];
  public hasIcon: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  /* toastType */
  private _toastType = 'warning';
  public get toastType(): string {
    return this._toastType;
  }
  public set toastType(type: string) {
    this._toastType = type;
  }

  constructor(
    protected toastrService: ToastrService,
    public toastPackage: ToastPackage,
  ) {
    super(toastrService, toastPackage);
  }

  public ngOnInit(): void {
    this.checkToastType();
    this.checkHasIcon();
  }

  public checkToastType(): void {
    this.toastType = this.toastClasses.split(' ')[0].replace('toast-', '');
  }

  public checkHasIcon(): void {
    switch (this.toastType) {
      case 'success':
        this.iconClasses.push('fa-check');
        break;
      case 'info':
        this.iconClasses.push('fa-pennant');
        break;
      case 'warning':
        this.iconClasses.push('fa-hand-paper');
        break;
      case 'error':
      case 'danger':
        this.iconClasses.push('fa-exclamation-triangle');
        break;
      default:
        break;
    }
  }
}
