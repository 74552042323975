import { Component, Inject, Input, OnInit } from '@angular/core';
import { ENV_CONFIG } from '../../model/environment-configuration';
import { EnvironmentConfiguration } from '../../model/environment-configuration';
import { User } from '../../model/user';
import { AuthService } from '../../service/auth.service';
import { NavigationService } from '../../service/navigation.service';

@Component({
  selector: 'core-header',
  templateUrl: './header.component.html'
})
export class HeaderComponent implements OnInit {

  @Input() type = 'primary';

  public customerServiceUrl: string;

  constructor(
    private authService: AuthService,
    private navService: NavigationService,
    @Inject(ENV_CONFIG) private envConfig: EnvironmentConfiguration
  ) {
    this.customerServiceUrl = this.envConfig.customerServiceUrl;
  }

  ngOnInit() {
  }

  public logout() {
    this.authService.logout();
    this.navService.goToSignedOut();
  }

  get user(): User {
    return this.authService.user();
  }

  get showDropdown() {
    return this.authService.isUserLoggedIn();
  }

}
